/* .calendarWrap {
	position: relative;
} */

.calendarElement {
	position: absolute;
	left: 0;
	transform: translateX(-50%);
	top: 40px;
	border: 1px solid #ccc;
	z-index: 999;
}

.calendarElement .rdrWeekDays {
	color: red;
}
