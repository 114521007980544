@media (min-width: 1024px) {
	.carousel {
		max-height: 80vh;
		/* max-width: 1000px; */
		border-radius: 5px;
	}
}

/* .carousel li.slide img {
} */
